import React from 'react'
import PropTypes from 'prop-types'
import StarRating from 'components/StarRating'
import { Flex, Box } from '@rebass/emotion'
import Avatar from 'components/Avatar'
import { css } from '@emotion/core'
import { SPACERS, COLORS, FONT_SIZES, MQ } from 'utils/styleHelpers'
import { NODE_TYPES } from 'utils/constants'
import { PrimaryButtonLink, PrimaryButtonAnchor } from 'components/PrimaryButton'
import { getLink } from 'utils/urlHelpers'
import LinkWrapper from 'components/LinkWrapper'

function DispensaryCarouselSlide({ dispensary }) {
	const { name, id, averageRating, totalReviews, address1, address2, city, state, zip, imageUrl } = dispensary
	const dispenaryUrl = getLink(dispensary, NODE_TYPES.DISPENSARIES)
	return (
		<Flex
			css={css`
				padding: ${SPACERS.L};
			`}
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
		>
			<Box alignSelf="center">
				<LinkWrapper to={dispenaryUrl}>
					<Avatar size={[200, 200]} src={imageUrl} />
				</LinkWrapper>
			</Box>
			<LinkWrapper to={dispenaryUrl}>
				<Box
					css={css`
						padding-top: ${SPACERS.L};
						padding-bottom: ${SPACERS.S};
						color: ${COLORS.GREEN};
						font-weight: 700;
						text-align: center;
						${MQ.M} {
							width: 200px;
						}
					`}
				>
					{name}
				</Box>
			</LinkWrapper>
			<LinkWrapper to={dispenaryUrl}>
				<Box alignSelf="center">
					<Flex
						css={css`
							flex-direction: row;
							${MQ.M} {
								flex-direction: column-reverse;
							}
							${MQ.L} {
								flex-direction: row;
							}
						`}
					>
						<Box
							css={css`
								${MQ.M} {
									align-self: center;
								}
							`}
						>
							<StarRating name="featuredDispenaryRating" starRatingId={id} value={averageRating} />
						</Box>
						<Box
							css={css`
								color: ${COLORS.YELLOW};
								font-size: ${FONT_SIZES.H5};
								font-weight: 500;
								${MQ.M} {
									align-self: center;
								}
							`}
						>
							{averageRating} Stars out of {totalReviews} reviews
						</Box>
					</Flex>
				</Box>
			</LinkWrapper>
			<LinkWrapper to={dispenaryUrl}>
				<Box alignSelf="center">
					<Flex
						css={css`
							padding-top: ${SPACERS.M};
							font-size: ${FONT_SIZES.H5};
							color: ${COLORS.BLACK};
							transition: color 0.5s;
							.dark & {
								color: ${COLORS.WHITE};
							}
						`}
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
					>
						<Box>{address1}</Box>
						<Box>{address2}</Box>
						<Box>
							{city} {state}, {zip}
						</Box>
					</Flex>
				</Box>
			</LinkWrapper>
			<Box
				css={css`
					padding-top: ${SPACERS.XL};
					width: 100%;
				`}
			>
				<Flex
					css={css`
						flex-direction: row;
						justify-content: space-around;
						${MQ.M} {
							justify-content: space-between;
						}
						${MQ.L} {
							justify-content: space-evenly;
						}
					`}
				>
					<Box>
						<PrimaryButtonLink
							color={COLORS.RED}
							textcolor={COLORS.WHITE}
							to={dispenaryUrl}
							state={{ activeTabIndex: 2, scrollTo: true }}
							fontSize={FONT_SIZES.H5}
						>
							View Menu
						</PrimaryButtonLink>
					</Box>
					<Box>
						<PrimaryButtonAnchor
							href={`https://www.google.com/maps/search/?api=1&query=${name}+${address1}+${address2}+${state}`}
							color={COLORS.BLACK}
							textcolor={COLORS.WHITE}
							bordercolordark={COLORS.SUPER_LIGHT_GRAY}
							fontSize={FONT_SIZES.H5}
							target="_blank"
						>
							Get Directions
						</PrimaryButtonAnchor>
					</Box>
				</Flex>
			</Box>
		</Flex>
	)
}
DispensaryCarouselSlide.propTypes = {
	dispensary: PropTypes.shape({
		name: PropTypes.string,
		id: PropTypes.string,
		imageUrl: PropTypes.string,
		averageRating: PropTypes.number,
		totalReviews: PropTypes.number,
		address1: PropTypes.string,
		address2: PropTypes.string,
		city: PropTypes.string,
		state: PropTypes.string,
		zip: PropTypes.string,
	}),
}
export default DispensaryCarouselSlide
