import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import StarRating from 'components/StarRating'
import { Flex, Box } from '@rebass/emotion'
import Avatar from 'components/Avatar'
import { css } from '@emotion/core'
import { SPACERS, COLORS, FONT_SIZES } from 'utils/styleHelpers'
import { NODE_TYPES, EVENT_TYPES, TRACKING_ACTIONS } from 'utils/constants'
import { PrimaryButtonLink } from 'components/PrimaryButton'
import { getLink } from 'utils/urlHelpers'
import { StrainNameLabel, DescriptionLabel } from './styles'
import Truncate from 'react-truncate'
import LinkWrapper from 'components/LinkWrapper'
import { SessionContext } from 'components/AuthContext'
import track, { TrackingPropType } from 'react-tracking'
import { stampTime, eventDispatch } from 'utils/tracker'
function StrainCarouselSlideLarge({ strain, tracking }) {
	const { name, id, description, averageRating, totalReviews, imageURL } = strain
	const strainUrl = getLink(strain, NODE_TYPES.strain)
	const { sessionID, uid, userIp, userLocation, version } = useContext(SessionContext)
	const trackingData = {
		action: TRACKING_ACTIONS.USER_SELECT,
		event: EVENT_TYPES.WR_TOP_RATED_STRAIN_SELECT,
		strainCategory: strain.category,
		nodeID: id,
		nodeType: NODE_TYPES.STRAINS,
		nodeLabel: name,
		sessionID,
		uid,
		userIp,
		userLocation,
		version,
	}
	return (
		<Flex
			css={css`
				padding-top: ${SPACERS.XL};
			`}
			flexDirection="row"
			justifyContent="center"
			alignItems="center"
		>
			<Box flexBasis="50%">
				<Flex flexDirection="column">
					<Box
						alignSelf="center"
						css={css`
							padding-bottom: ${SPACERS.XL};
						`}
					>
						<LinkWrapper to={strainUrl} tracker={trackingData}>
							<Avatar size={[350, 350]} src={imageURL} />
						</LinkWrapper>
					</Box>
					<Box alignSelf="f">
						<Flex flexDirection="row" justifyContent="center">
							<Box
								css={css`
									padding-right: ${SPACERS.M};
								`}
							>
								<PrimaryButtonLink
									color={COLORS.RED}
									textcolor={COLORS.WHITE}
									to={strainUrl}
									state={{ activeTabIndex: 2 }}
									fontSize={FONT_SIZES.H5}
									onClick={() => {
										tracking.trackEvent(trackingData)
									}}
								>
									Locate Product
								</PrimaryButtonLink>
							</Box>
							<Box
								css={css`
									padding-left: ${SPACERS.M};
								`}
							>
								<PrimaryButtonLink
									color={COLORS.BLACK}
									textcolor={COLORS.WHITE}
									bordercolordark={COLORS.SUPER_LIGHT_GRAY}
									fontSize={FONT_SIZES.H5}
									to={strainUrl}
									onClick={() => tracking.trackEvent(trackingData)}
								>
									Learn More
								</PrimaryButtonLink>
							</Box>
						</Flex>
					</Box>
				</Flex>
			</Box>
			<Box flexBasis="50%" alignSelf="flex-start">
				<Flex flexDirection="column" justifyContent="flex-start">
					<Box
						css={css`
							padding-top: ${SPACERS.L};
							color: ${COLORS.GREEN};
						`}
						alignSelf="flex-start"
					>
						Top Rated Product
					</Box>
					<LinkWrapper to={strainUrl} tracker={trackingData}>
						<Box>
							<StrainNameLabel>{name}</StrainNameLabel>
						</Box>
					</LinkWrapper>
					<LinkWrapper to={strainUrl} tracker={trackingData}>
						<Box alignSelf="flex-start">
							<Flex flexDirection="row">
								<Box>
									<StarRating
										name="featuredDispenaryRating"
										starRatingId={id}
										value={averageRating}
									/>
								</Box>
								<Box
									css={css`
										color: ${COLORS.YELLOW};
										font-size: ${FONT_SIZES.H5};
										font-weight: 500;
									`}
								>
									{averageRating} Stars out of {totalReviews} reviews
								</Box>
							</Flex>
						</Box>
					</LinkWrapper>
					<Box
						css={css`
							padding-top: ${SPACERS.M};
						`}
					>
						<Truncate
							css={css`
								color: ${COLORS.BLACK};
								font-size: ${FONT_SIZES.H5};
								transition: color 0.5s;
								.dark & {
									color: ${COLORS.WHITE};
								}
							`}
							lines={2}
							ellipsis={
								<span>
									...{' '}
									<LinkWrapper to={strainUrl} tracker={trackingData}>
										Read More
									</LinkWrapper>
								</span>
							}
						>
							<DescriptionLabel dangerouslySetInnerHTML={{ __html: description }}></DescriptionLabel>
						</Truncate>
					</Box>
				</Flex>
			</Box>
		</Flex>
	)
}
StrainCarouselSlideLarge.propTypes = {
	strain: PropTypes.shape({
		name: PropTypes.string,
		description: PropTypes.string,
		imageURL: PropTypes.string,
		id: PropTypes.string,
		averageRating: PropTypes.number,
		totalReviews: PropTypes.number,
		category: PropTypes.string,
	}),
	tracking: TrackingPropType,
}
export default track({ timestamp: stampTime() }, { dispatch: data => eventDispatch(data) })(StrainCarouselSlideLarge)
